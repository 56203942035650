import Vue from 'vue'
import Vuex from 'vuex'
import * as Sentry from "@sentry/vue";
import createPersistedState from "vuex-persistedstate";

import SettingModule from './modules/setting.js'
import SettingPlugin from './plugins/setting.js'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLoggedIn: false,
        token: null,
        user: null,
        loginMail: null,
        referral: {
            hash: ''
        },
        language: null,
        chat: {
            realized_new_message: false,
            lastConversationId: null
        },
        redirectAfterLogin: false
    },
    mutations: {
        loginUser(state, data) {
            state.token = data.token;
            state.token.session_start = new Date().getTime();
            state.user = data.user;
            state.loginMail = state.user.email;
            state.isLoggedIn = true;
            state.language = data.user.language;
        },
        logoutUser(state) {
            state.isLoggedIn = false;
            state.token = null;
            state.user = null;
            state.chat.lastConversationId = null;
            state.chat.realized_new_message = null;
            if (process.env.NODE_ENV === 'production') {
                Sentry.configureScope(scope => scope.setUser(null));
            }
        },
        updateUser(state, user) {
            state.user = user;
        },
        updateLanguage(state, language){
            state.language = language;
        },
        addressIsFilled(state) {
            state.user.address_is_filled = true;
        },
        lockSession(state) {
            state.token = null;
        },
        setRefHash(state, refHash) {
            state.referral.hash = refHash;
        },
        emailVerified(state) {
            if (state.user) {
                state.user.email_verified = true;
            }
        },
        redirectAfterLogin(state, link) {
            if (link !== '/lock' && link !== '/login')  {
                state.redirectAfterLogin = link;
            }
        },
        twoFactorAuthEnabled(state, google2fa_enabled_at) {
            state.user.google2fa_enabled_at = google2fa_enabled_at;
        },
        twoFactorAuthDisabled(state) {
            state.user.google2fa_enabled_at = null;
        },
        setCurrentConversation(state, conversation_id) {
            state.chat.lastConversationId = conversation_id;
        },
        toggleInstructionState(state, stateName) {
            state.user.instruction_state[stateName] = !state.user.instruction_state[stateName];
        },
        realizedNewMessage(state) {
            state.chat.realized_new_message = true;
        }
    },
    getters: {
        hasFirstline: state => {
            if(state.user) {
                if (state.user.firstline > 0) {
                    return state.user.firstline;
                }
            }

            return false;
        },
        hasPermissionTo: (state) => (permission) =>
        {
            return state.user && state.user.permissions && state.user.permissions.includes(permission)
        },
        user: state => prop => {
            if(state.user) {
                if (state.user[prop]) {
                    return state.user[prop];
                }
            }

            return undefined;
        }
    },
    modules: {
        setting: SettingModule,
    },
    plugins: [
        createPersistedState({
            reducer: (persistedState) => {
                const stateFilter = JSON.parse(JSON.stringify(persistedState)); // deep clone
                ['offsidebarOpen', 'asideToggled', 'horizontal'] // states which we don't want to persist.
                    .forEach(item => delete stateFilter.setting[item]);
                return stateFilter
            }
        }),
        SettingPlugin
    ]
})