//Sale
const BuyPlant = () => import ('./../views/BuyPlant.vue');
const Participation = () => import ('./../views/Participation.vue');

export default {
    requiresAuth: [
        {
            path: 'sale/:room_name/:amount?',
            name: 'Growing:SaleProduct',
            meta: {
                requiresAuth: true,
                modulesRequired: ['Wallet', 'Growing']
            },
            component: BuyPlant
        },
        {
            path: 'swap',
            name: 'Growing:Participation',
            meta: {
                requiresAuth: true,
                modulesRequired: ['Wallet', 'Growing']
            },
            component: Participation
        },
        {
            path: 'sale/:amount?',
            name: 'Growing:Sale',
            meta: {
                requiresAuth: true,
                modulesRequired: ['Wallet', 'Growing']
            },
            component: BuyPlant
        },
    ]
};